import React from 'react'
import {Route,Redirect} from 'react-router-dom'
import {getToken} from './Common'

const PrivetRouter = ({component:Component,...rest}) => {
  return (
   <Route 
   {...rest}
   render={props=>{
      return getToken() ? <Component {...props}/>
       :<Redirect to={{pathname:"/login",state:{from:props.location}}} />
   }}
   />
  )
}

export default PrivetRouter