import React, { useState,useEffect,useRef} from 'react'
import axios from 'axios'
import moment from 'moment'
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import {FormControl,TextField} from '@mui/material';
import { Button, Container,Col ,Table} from '@themesberg/react-bootstrap';
const lurl="http://localhost:3001";
const url="https://alwafi.thesmartlogic.com";
function patchFilterFactory(filterFactory, onFilteredData) {
    return (...args) => {
       const {
          createContext,
          options
       } = filterFactory(...args)
       return {
          createContext: (...args) => {
             const {
                Provider: BaseProvider,
                Consumer
             } = createContext(...args)
             const Provider = class FilterProvider extends BaseProvider {
                componentDidUpdate() {
                   onFilteredData(this.data)
                }
             }
             return {
                Provider,
                Consumer
             }
          },
          options
       }
    }
  }
  const pagination = paginationFactory({
    page: 1,
    sizePerPage: 10,
    lastPageText: '>>',
    firstPageText: '<<',
    nextPageText: '>',
    prePageText: '<',
    showTotal: true,
    alwaysShowAllBtns: true,
    onPageChange: function (page, sizePerPage) {
      console.log('page', page);
      console.log('sizePerPage', sizePerPage);
    },
    onSizePerPageChange: function (page, sizePerPage) {
      console.log('page', page);
      console.log('sizePerPage', sizePerPage);
    }
  });
  
const ExpensesRep=()=>{
    const [data,setData]=useState([])
    const columns = [
        {
          dataField: "action_date",
          text: "تاريخ الحركة",
          sort: true,
          
        },
      
        {
            dataField:"value",
            text: "المبلغ",
            sort: true,
            rowspan: 2,
            align: 'center',
            valign: 'middle',
            sortable: true,
            
          },
          {
            dataField:"body",
            text: "الوصف",
            sort: true,
            rowspan: 2,
            align: 'start',
            valign: 'middle',
            sortable: true,
            
          },
        
        
      
      ]
      

    useEffect(() => {
        (async () => {
            axios.get(`${url}/getexpen`)
            .then((res)=>{ 
              setData(res.data);
             
            })
         
        })();
      }, []);
        
      const factory = patchFilterFactory(filterFactory, (filteredData) => {
 
    })
    return(
        <Container>
  <BootstrapTable className="table table-striped"
       filter={factory() }
        bootstrap4
        keyField="id"
        data={data}
        columns={columns}
        pagination={pagination}
        hover={true} search={true} />
  </Container>
    )
}
export default ExpensesRep