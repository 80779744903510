import React,{useState,useEffect,useRef,lazy,Suspense} from 'react'
import axios from 'axios'
import './App.css';
import "bootstrap/dist/css/bootstrap.css"
import Login from './Components/Login'
import Content from './Pages/Content';
import PolPage2 from './Pages/PolPage2'

import {BrowserRouter as Router,useHistory} from 'react-router-dom'
import ShowPage from './Pages/ShowPage';
import Nave from './Components/Nav';
import Footer from './Components/Footer';

import RepTable from "./Report/RepTable";

import EditPage from './Pages/EditPage';
import Editpage2 from './Pages/Editpage2';
import { getToken, removeUserSession, setUserSession } from './Components/Common';
import react from 'react';





function App() {
const {tok,setTok}=useState(false)
  useEffect(()=>{
    document.title = "بوابة المسافر للتأمين"
const token=getToken();
if(!token){return}
axios.get(`http://localhost:3000/verifyToken?token=${token}`)
.then(res=>{
setUserSession(res.data.token,res.data.user)

setTok(true)
}).catch(error=>{
removeUserSession()
})
  },[])
  return (
    
  
   <Router  >
  
    <div className="app">
      {tok ?

       <react.Fragment>
        <Login /> 

      </react.Fragment>
:
      <react.Fragment>
       <Nave/>
       <Content />
       <Footer />
      
       </react.Fragment>
      
       }
      </div>
    
   </Router>
   
  

  );
}

export default App;
