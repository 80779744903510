import React from 'react'
import {Image, Navbar, Nav ,NavDropdown } from 'react-bootstrap'
import * as Faicons from 'react-icons/all'
import user from '../Images/user.jpg'
import navlogo from '../Images/navlogo.png'
import '../Style/Nav.css'
import { Link ,useHistory } from 'react-router-dom'
import { getUser, removeUserSession } from './Common'


 const Nave = (props) => {
  const history = useHistory ()
  const handlelogin=()=>{
    removeUserSession()
    history.push('/login')
  }
  const usr=getUser();
    return (
        <div >
         
        <Navbar className="navbar-main"  variant="b">
                  <Navbar.Brand >
            <img className="logo"  src={user}  width="40px" height="40px"  />
            
                   </Navbar.Brand>
                   
          <Navbar.Toggle className="coloring" />
          
          <Navbar.Collapse className="navdiv">
            <Nav className="navdiv">
              <NavDropdown title="الاعدادات">
                <Link to="/login" onClick={handlelogin}>تسجيل الخروج</Link>
              </NavDropdown>
              <Nav.Link className="nitems" href="#blog">الدعم الفني</Nav.Link>
              <Nav.Link className="navdiv" href="#about-us">من نحن</Nav.Link>
            </Nav>
            <Navbar.Brand >
                       <video class="imglogo rounded-circle" autoplay="ok" loop="2rem" muted="" data-speedsize-src="https://media.jfrog.com/wp-content/uploads/2021/12/22174028/icon-shield.mp4" playsinline="true" loading="lazy" src="https://speedmedia.jfrog.com/08612fe1-9391-4cf3-ac1a-6dd49c36b276/https://media.jfrog.com/wp-content/uploads/2021/12/22174028/icon-shield.mp4/v_muted,mxw_1440,s_vp9,s_av1,f_auto" wz_dt_ref="true">
                          <source src="https://media.jfrog.com/wp-content/uploads/2021/12/22174028/icon-shield.mp4" type="video/mp4"/>
                          Your browser does not support the video tag.
                        </video>
          </Navbar.Brand>
            
          </Navbar.Collapse>
        
        </Navbar>
       
      </div>
    
    )
}

 export default Nave