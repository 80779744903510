import React  from "react";
import '../Style/Footer.css'

const Footer =()=>{
    return(
        <footer className='footer mt-6 py-3  text-white'>
        <div className='footer-container'>Copyright © 2022 - Developed By N.Sawi</div>
        
      </footer>
    )
}
export default Footer